<template>
    <div>
        <v-select 
            v-model="txt_supplier"
            :items="suppliers"
            offset-y
            item-text="item2"
            item-value="item1"
            auto-select-first
            :rules="[rules.required]"
            required
            placeholder="Kies leverancier"
            dense></v-select>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from "@/mixins/data.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
export default {
    name: 'SupplierTypeDropdown',
    props: 
    {
        supplier: String
    },
    mixins: [DataMixin, MenuHelperMixin],
    beforeMount()
    {
        this.txt_supplier = this.supplier;
    },
    data() {
        return {
            txt_supplier: {},
        }
    },
    computed: {
        ...mapState({
            suppliers: state => state.menuHelper.suppliers
        })
    },
    watch: {
        supplier: {
            deep: true,
            handler() {
                this.txt_supplier = this.supplier;
            }
        },
        txt_supplier: 'onValueChanged'
    },
    methods:
    {
        onValueChanged()
        {
            this.$emit('changed', this.txt_supplier);
        }
    }
}
</script>