<template>
    <div :class="templateClass" class="editable-input">
        <div v-if="!editText" class="ma-0">{{text}}
            <img class="icon edit-icon" :src="editIcon" :alt="altText" @click="editTitle()">
        </div>
        <v-text-field v-if="editText"
            ref="title" flat
            class="pa-0 ma-0"
            v-model="text"
            :readonly="editText == false"
            :append-outer-icon="editText ? 'mdi-check' : ''"
            @keydown.native.enter="editTitle()"
            @click:append-outer="editTitle()"
            ></v-text-field>
    </div>
</template>

<script>
export default {
    name: 'EditableInput',
    props: 
    {
        textInput: String,
        altText: String,
        templateClass: String
    },
    beforeMount()
    {
        this.text = this.textInput;
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        editText: false,
        text: ""
    }),
    watch: {
        textInput: {
            deep: true,
            handler() {
                this.text = this.textInput;
            }
        },
    },
    methods:
    {
        editTitle()
        {
            this.editText = !this.editText;
            this.$nextTick(() => {
                const title = this.$refs.title;

                if (title)
                {
                    title.focus();
                }
                
            })

            if (!this.editText)
            {
                this.$emit('updated', this.text);
            }
        }
    }
}
</script>
<style>
.editable-input .edit-icon {
   width: 20px; 
   height: 20px; 
}
</style>
