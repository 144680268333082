<template>
    <div>
         <v-select 
            v-model="txt_billingType"
            :items="billingTypes"
            item-text="item2"
            item-value="item1"
            return-object
            offset-y
            placeholder="Type uitgave"
            dense></v-select>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'BillingTypeDropdown',
    props: 
    {
        billingType: Number
    },
    beforeMount()
    {
        this.txt_billingType = this.billingTypes.find(x => x.item1 == this.billingType);
    },
    data() {
        return {
            txt_billingType: {},
        }
    },
    computed: {
        ...mapState({
            billingTypes: state => state.menuHelper.billingTypes
        })
    },
    watch: {
        txt_billingType: 'onValueChanged'
    },
    methods:
    {
        onValueChanged()
        {
            this.$emit('changed', this.txt_billingType.item1);
        }
    }
}
</script>