<template>
    <div>
        <v-select
            v-model="txt_vatRate"
            :items="VATRates"
            item-text="item2"
            item-value="item1"
            :rules="[rules.required]"
            required
            return-object
            placeholder="Kies btw-tarief"
            dense></v-select>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from "@/mixins/data.mixin";
export default {
    name: 'VATRateDropdown',
    props: 
    {
        vatRate: Number
    },
    mixins: [DataMixin],
    beforeMount()
    {
        if (this.supplier)
        {
            this.txt_vatRate = this.supplierTypes.find(x => x.item1 == this.vatRate);
        }
    },
    data() {
        return {
            txt_vatRate: {},
        }
    },
    computed: {
        ...mapState({
            VATRates: state => state.menuHelper.VATRates
        })
    },
    watch: {
        txt_vatRate: 'onValueChanged'
    },
    methods:
    {
        onValueChanged()
        {
            this.$emit('changed', this.txt_vatRate.item1);
        }
    }
}
</script>