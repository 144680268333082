<template>
    <div class="px-2">
        <v-container>
            <v-row no-gutters>
                <v-col cols="7">
                    <EditableInput 
                    @updated="updateTitle"
                    :textInput="invoiceTitle" 
                    :templateClass="`display-1 editable-header`"
                    :altText="invoiceTitle" />
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4">
                    <BillingTypeDropdown :billingType="billingType" @changed="billingType = $event" />
                </v-col>
            </v-row>
        <v-form ref="form" class="ma-2"
                v-model="valid"
                lazy-validation>
                <v-row no-gutters>
                    <v-col cols="6" class="my-3">
                        Account
                    </v-col>
                    <v-col cols="6" class="ma-auto pa-0">
                        <v-text-field
                            placeholder="Kies account"
                            v-model="account"
                            dense></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="my-3">
                        Roadtrip
                    </v-col>
                    <v-col cols="6" class="ma-auto pa-0">
                            <v-select
                            offset-y
                            v-model="roadtrip"
                            :items="roadTrips"
                            placeholder="Kies roadtrip"
                            dense></v-select>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="my-3">
                        Leverancier*
                    </v-col>
                    <v-col cols="6" class="ma-auto pa-0">
                        <SupplierDropdown :supplier="leverancier" @changed="leverancier = $event" />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="my-3">
                        Prijs ex btw*
                    </v-col>
                    <v-col cols="6" class="ma-auto pa-0">
                        <v-text-field
                            :rules="[rules.required]"
                            required
                            v-model="priceExbtw"
                            dense></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="my-3">
                        Btw-tarief*
                    </v-col>
                    <v-col cols="6" class="ma-auto pa-0">
                        <VATRateDropdown :vatRate="vatRate" @changed="vatRate = $event" />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="my-3">
                        Notitie
                    </v-col>
                    <v-col cols="6" class="ma-auto pa-0">
                        <v-text-field
                            v-model="notitie"
                            dense></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters v-if="invoiceUploaded">
                    <v-col cols="6" class="my-3">
                        Bestand
                    </v-col>
                    <v-col cols="6" class="ma-auto pa-0 uploaded-pdf">
                        <p v-bind="filename">{{filename}}</p>
                        <v-spacer></v-spacer>
                        <div>
                            <img class="delete-icon" :src="deleteIcon" alt="Delete" @click="deletePdf()" />
                        </div>
                    </v-col>
                </v-row>
                <input type="file" ref="file" style="display: none" accept=".pdf" @change="previewFile">
                <v-btn color="primary" block @click="uploadPDF()" class="mt-5 mb-5" v-if="!invoiceUploaded">
                    Upload pdf
                    <v-icon
                        right
                        dark>
                        mdi-cloud-upload
                    </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
            </v-form>
        </v-container>
        <div class="bottom-section ma-2">
            <v-container>
                <v-row>
                    <v-col cols="12 py-0">
                        <v-checkbox v-model="isBetaald">
                            <template v-slot:label>
                                <div>
                                De factuur is betaald
                                </div>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col class="pt-0">
                        <v-btn color="primary" block @click="saveInvoice()" >
                        Opslaan
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
      </div>
</template>

<script>
import EditableInput from '@/components/common/EditableInput';
import VATRateDropdown from '@/components/common/options/VATRateDropdown';
import BillingTypeDropdown from '@/components/common/options/BillingTypeDropdown';
import SupplierDropdown from '@/components/common/options/SupplierDropdown';
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";

export default {
    name: 'NieuweFactuur',
    components: 
    {
        EditableInput,
        VATRateDropdown,
        BillingTypeDropdown,
        SupplierDropdown
    },
    data: () => ({
        deleteIcon: require('@/assets/icons/delete.svg'),
        invoiceTitle: "Nieuwe factuur",
        roadTrips: 
        [
            "Tulpenrit",
            "Zeeland"
        ],
        valid: true,
        account: null,
        billingType: null,
        roadtrip: null,
        leverancier: null,
        priceExbtw: null,
        vatRate: null,
        notitie: null,
        isBetaald: false,
        filename: ".pdf",
        invoiceUploaded: false
    }),
    mixins: [DataMixin, FormatMixin],
    mounted () 
    {
        console.log(this.$route.params);
        const date = this.$route.params.trip.slice(-10);
        const title = this.$route.params.trip.slice(0, -11);
        console.log(date);
        console.log(title);

        // TODO: auto select the roadtrip droddown based from the trip url param 
    },
    methods: 
    {
        updateTitle(title)
        {
           this.invoiceTitle = title;
        },
        uploadPDF()
        {
            this.resetFileSelected();
            this.$refs.file.click();
           
        },
        resetFileSelected()
        {
            if (this.$refs.file != null)
            {
                this.$refs.file.value = null;
            }
        },
        previewFile()
        {
            this.filename = this.$refs.file.files[0].name;
            this.invoiceUploaded = true;
        },
        deletePdf()
        {
            this.invoiceUploaded = false;
        },
        saveInvoice()
        {
            const valid = this.$refs.form.validate();
            if(valid)
             {
                let invoiceRequest = new FormData();
                invoiceRequest.append('Title', this.invoiceTitle);
                invoiceRequest.append('PriceExVAT', Number(this.priceExbtw));
                invoiceRequest.append('ExpenseType', this.billingType);
                invoiceRequest.append('VATRate', this.vatRate);
                invoiceRequest.append('Note', this.notitie);

                // todo: add to UI?
                invoiceRequest.append('Received', this.getToday());
                invoiceRequest.append('Paid', this.getToday());

                invoiceRequest.append('AccountId', this.account);
                invoiceRequest.append('Roadtrip', this.roadtrip);
                invoiceRequest.append('Supplier', this.leverancier);
                
                invoiceRequest.append('PdfFile', this.$refs.file.files[0]);

                this.axios
                .post("expense/admin/SaveInvoice", invoiceRequest,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            }

            // TODO: Add user notif that is is saved.

        }
    }
}
</script>
<style scoped>

.uploaded-pdf 
{
    display: inline-flex;
    position: relative;
}

.delete-icon 
{
    width: 20px;
    height: 20px;
    margin: 3px;
    cursor: pointer;
}
</style>